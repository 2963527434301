<template>
  <div>
    <button
      type="button"
      class="update-payment-method" 
      @click="openModal()"
    >
      Update Payment Method
    </button>
    <Modal id="paddle-plan" ref="paddlePlan" title="Update Payment Method" size="lg">
      <iframe :src="PaddleUpdateURL" frameborder="0"  style="width:100%;height:540px;"></iframe>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../Layout/Modal.vue';
export default {
    props:["PaddleUpdateURL"],
 components:{
  Modal,
 },
  methods: {
    openModal(){
         
      this.$refs.paddlePlan.open()
        
    },
    
    
     
  },
 
};
</script>

<style scoped>
.update-payment-method:hover{
  box-shadow: 0px 0px 0px 2px var(--primary-color);
}
.update-payment-method:active{
  box-shadow: 0px 0px 0px 2px var(--primary-color);
}
.update-payment-method{
border:none;
outline:none;
  display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 6px;
background: #F6F6F8;
color:#73738D;
transition: 0.3s ease all;
}

</style>
